@import '../../theme/main';

.dop-account-link-page {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-color: #ECECEC;

  .MuiPaper-root {
    max-width: 450px;
  }

  .dop-account-link {
    margin-top: 32px;
    border-radius: 5px;
    padding: 32px;

    &__title {
      max-width: 300px;
      margin: 32px auto;

      h1 {
        font-size: 1.5rem;
        font-weight: 400;
        width: 100%;
        text-align: center;
      }
    }

    &__logos-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .logo {
        width: 75px;
        margin-left: 12px;
        margin-right: 12px;

        &__doppio {

        }

        &__arrows {

        }

        &__game {

        }

        img {
          max-width: 100%;
          height: auto;
        }
      }
    }

    &__body {
      ul {
        li {
          margin-bottom: 12px;
        }
      }

      p:last-of-type {
        margin-bottom: 38px;
      }
    }

    &__providers {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 38px;

      .provider {
        width: 220px;
        max-width: 220px;
        margin-bottom: 18px;
        display: flex;
        padding-top: 3px;
        padding-bottom: 3px;
        text-decoration: none;
        color: #000;

        &:active {
          color: #000;
        }

        &--google {
          background-color: #FFF;
          border: 1px solid rgba(0,0,0,.7);

          &__icon {
            background-image: url(../../assets/icons/google.svg);
            background-repeat: no-repeat;
            background-size: 75%;
            background-position: center;
          }
        }

        &--amazon {
          background-color: $amazon-bg-color;
          border: 1px solid rgba(0,0,0,.1);

          &__icon {
            background-image: url(../../assets/icons/amazon.svg);
            background-repeat: no-repeat;
            background-size: 75%;
            background-position: center;
          }
        }

        &__label {
          padding: 12px;
          text-align: center;
          flex: 1;
        }

        &__icon {
          display: inline-block;
          border-right: 1px solid rgba(0,0,0,.7);

          width: 45px;
          height: 44px;
        }

      }
    }

    &__privacy {
      text-align: center;
    }
  }
}
