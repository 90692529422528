.dop-jumbo {
  height: 200px;
  // background-image: url(../../../assets/portal-bg.png);
  background-color: rgba(255,255,255,0);
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(32, 26, 22, 0.7);
    z-index: 20;
  }

  &__text {
    color: #ECECEC;
    z-index: 30;
    font-size: unquote("min(max(40px, 6vw), 60px)");
    font-weight: bold;
  }
}
