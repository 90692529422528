.dop-games {
  .panel-header {
    padding: 16px 0;
    border-bottom: 1px solid rgba(0,0,0,.3);
    margin: 0 24px;
    font-size: 24px;
    font-weight: 400;
  }

  .game {
    display: flex;
    padding: 32px 24px;
    flex-wrap: wrap;

    @media only screen and (max-width: 466px) {
      justify-content: center;
    }

    &__banner {
      width: 150px;
      height: 150px;

      &.cerrado {
        background-image: url(../../../assets/games/Three_percent_icon.png);
        background-position: center;
        background-size: 180%;
        background-repeat: no-repeat;
      }

      &.arpeggio {
        background-image: url(../../../assets/games/vortex_icon.png);
        background-position: left;
        background-size: 300%;
        background-repeat: no-repeat;
      }

      &.french {
        background-image: url(../../../assets/games/voicequest_icon.png);
        background-position: left;
        background-size: 300%;
        background-repeat: no-repeat;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h1 {
        margin: 0 21px;
        font-size: 18px;
        font-weight: 500;
        font-style: italic;
        letter-spacing: 4px;
        text-transform: uppercase;

        @media only screen and (max-width: 466px) {
          margin-bottom: 12px;
        }
      }

      p {
        margin: 0 21px;
        color: rgba(0,0,0,.7);
      }

      @media only screen and (max-width: 466px) {
        margin-top: 24px;
        text-align: center;
      }
    }

    &__actions {
      margin-left: auto;
      align-self: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 19px 0;

      button {
        width: 100%;
        display: block;
        text-align: center;
        border: 3px solid #000;
        color: #000;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 12px 18px;
        transition: background-color .1s 0s ease-in-out, color .1s 0s ease-in-out;
        margin-bottom: 6px;

        &:hover {
          background-color: #000;
          color: #FFF;
        }

        @media only screen and (max-width: 676px) {
          width: 100%;
        }
      }

      @media only screen and (max-width: 676px) {
        margin-top: 24px;
        width: 100%;
        text-align: center;
      }

      @media only screen and (max-width: 466px) {

      }
    }
  }
}
