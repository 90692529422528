.dop-header {
  // height: 60px;
  background-color: rgba(182, 3, 58, 1);
  display: flex;
  justify-content: center;
  // padding: 0 20px 20px 0;

  &__btn {
    text-align: center;
  }
  &__brand {
    // display: block;
    // width: 180px;
    padding: 0px 0px 0px 15%;

    img {
      max-width: 55%;
      min-width: 110px;
      height: auto;
    }
  }

  nav {
    margin-left: auto;

    ul {
      list-style: none;
      padding: 0;
      display: flex;
      align-items: center;

      li {
        a {
          text-decoration: none;
          border: 3px solid #FFF;
          border-radius: 0;
          color: #FFF;
          font-size: 13px;
          font-weight: bold;
          text-transform: uppercase;
          width: auto;
          padding: 6px 12px;
          transition: background-color .1s 0s ease-in-out, color .1s 0s ease-in-out;

          &:hover {
            background-color: #FFF;
            color: #000;
          }
        }

        p {
          font-size: 14px;
          color: #FFF;
          margin: 0 16px 0 0;
          text-align: end;
        }
      }
    }
  }
}
