@import './variables';
@import './mixins';

html, body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  text-align: inherit;
  outline: none;

  cursor: pointer;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}


