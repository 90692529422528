@import '../../theme/main';

.dop-home {
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // align-items: center;
  background-color: transparent;
  &__main-container {
    padding-top: 64px;
    padding-bottom: 64px;
    @include containerPadding();

    background-color: #ECECEC;
  }
}
