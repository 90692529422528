@import '../../theme/main';

.dop-login-page {
  // height: calc(100vh);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .MuiPaper-root {
    max-width: 350px;
  }

  .dop-login {
    //border: 1px solid black;
    border-radius: 5px;
    padding: 32px;
    //box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.20);

    h1 {
      font-size: 2rem;
      margin-bottom: 32px;
    }

    &__body {
      p:last-of-type {
        margin-bottom: 48px;
      }
    }

    &__providers {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .provider {
        width: 220px;
        max-width: 220px;
        margin-bottom: 18px;
        display: flex;
        padding-top: 3px;
        padding-bottom: 3px;

        &--google {
          background-color: #FFF;
          border: 1px solid rgba(0,0,0,.7);

          &__icon {
            background-image: url(../../assets/icons/google.svg);
            background-repeat: no-repeat;
            background-size: 75%;
            background-position: center;
          }
        }

        &--amazon {
          background-color: $amazon-bg-color;
          border: 1px solid rgba(0,0,0,.1);

          &__icon {
            background-image: url(../../assets/icons/amazon.svg);
            background-repeat: no-repeat;
            background-size: 75%;
            background-position: center;
          }
        }

        &__label {
          padding: 12px;
          text-align: center;
          flex: 1;
        }

        &__icon {
          display: inline-block;
          border-right: 1px solid rgba(0,0,0,.7);

          width: 45px;
          height: 44px;
        }

      }
    }
  }
}
