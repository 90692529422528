footer {
  background-color: #201a16;
  .links {
    margin-top: 0;
    padding: 64px 32px;
    list-style: none;
    display: flex;
    justify-content: center;


    li {
      margin: 16px;
      a {
        color: #FFF;
        text-decoration: none;
        font-family: 'Ubuntu Mono', sans-serif;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 2px;
      }
    }
  }

  .copyright {
    color: rgba(255,255,255,.4);
    font-weight: 300;
    font-size: 16px;
    letter-spacing: .5px;
    text-align: center;
    padding-bottom: 32px;
  }
}
