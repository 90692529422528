@import './variables';

@mixin media-mobile-sm {
  @media (max-width: 375px) { @content }
}

@mixin media-mobile-l {
  @media (max-width: 425px) { @content }
}

@mixin media-tablet {
  @media (max-width: 767px) { @content }
}

@mixin media-laptop-sm {
  @media (max-width: 900px) { @content }
}

@mixin media-laptop {
  @media (max-width: 1024px) { @content }
}

@mixin media-desktop-sm {
  @media (max-width: 1120px) { @content }
}

@mixin media-desktop {
  @media (max-width: 1280px) { @content }
}

@mixin media-desktop-lg {
  @media (max-width: 1440px) { @content }
}

@mixin font($name, $lineHeight: 1.25, $style: 'normal') {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family:  'Ubuntu', sans-serif;
  font-weight:  unquote(map-get($font-weights, $name));
  font-style:   unquote($style);
  line-height:  $lineHeight;
  font-size: unquote(map-get($font-sizes, $name));
}

@mixin containerPadding() {
  padding-left: 220px;
  padding-right: 220px;
  //max-width: 1000px; // 1440 - (220 + 220)
  //margin: 0 auto;

  @include media-desktop {
    padding-left: 84px;
    padding-right: 84px;
  }

  @include media-desktop-sm {
    padding-left: 24px;
    padding-right: 24px;
  }

  @include media-laptop {
    padding-left: 24px;
    padding-right: 24px;
  }

  @include media-laptop-sm {
    padding-left: 24px;
    padding-right: 24px;
  }
}
